<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 col-lg-6 g c">
      <div class="card">
        <div class="card-header">
          <h4>
            <span v-if="!edit">
              <i class="fa fa-plus"></i>
              اضافة باص جديد
            </span>
            <span v-if="edit">
              <i class="fa fa-edit"></i>
              تعديل الباص
            </span>
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for=""> العنوان (إسم الباص او رقمه) </label>
            <input type="text" class="form-control" v-model="bus.title" />
          </div>
          <div class="form-group">
            <label for="">التفاصيل (اختياري)</label>
            <textarea
              class="form-control"
              v-model="bus.details"
              rows="3"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="">القسم</label>
            <select class="form-control" v-model="bus.section_id">
              <option :value="null">-- اختر --</option>
              <template v-for="section in sections">
                <option :value="section._id" :key="section._id">
                  {{ section.title }}
                </option>
              </template>
            </select>
          </div>
          <div class="form-group">
            <label for="">خط السير الافتراضي (يتغير عند بدء الرحلة)</label>
            <select class="form-control" v-model="bus.group_id">
              <option :value="null">-- اختر --</option>
              <template v-for="group in groups">
                <option :value="group._id" :key="group._id">
                  {{ group.title }}
                </option>
              </template>
            </select>
          </div>
          <div class="g">
            <b-form-checkbox
              v-model="bus.send_go"
              name="check-button"
              class="custom-control-success"
              inline
            >
              <h5>
                <i class="fa fa-arrow-up"></i>
                ارسال اشعارات لاولياء الامور في رحلة الذهاب
              </h5>
            </b-form-checkbox>
          </div>
          <div class="g">
            <b-form-checkbox
              v-model="bus.send_back"
              name="check-button"
              class="custom-control-success"
              inline
            >
              <h5>
                <i class="fa fa-arrow-down"></i>
                ارسال اشعارات لاولياء الامور في رحلة العودة
              </h5>
            </b-form-checkbox>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-primary" @click="done()">
              <span v-if="!edit">
                اضافة الباص <i class="fa fa-arrow-left"></i> </span
              ><span v-if="edit">
                تعديل الباص <i class="fa fa-arrow-left"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BFormCheckbox, BOverlay } from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
    BOverlay,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      loading: true,
      bus: {
        title: "",
        details: "",
        send_go: true,
        send_back: true,
        group_id: null,
        section_id: null,
      },
      edit: false,
      groups: [],
      sections: [],
    };
  },
  created() {
    var g = this;
    if (!checkPer("busses")) {
      this.$router.push("/per");
      return false;
    }
    $.post(api + "/user/groups/list", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.groups = r.response;
        $.post(api + "/user/sections/list", {
          jwt: g.user.jwt,
        })
          .then(function (r) {
            g.sections = r.response;
            if (!g.bus.section_id && !window.location.hash) {
              g.bus.section_id = r.response[0]?._id;
            }
            g.loading = false;
          })
          .catch(function (e) {
            console.log(e);
            alert("حدث خطأ..");
          });
      })
      .catch(function (e) {
        console.log(e);
        alert("حدث خطأ..");
      });
    if (window.location.hash) {
      g.edit = "true";
      g.loading = true;
      $.post(api + "/user/busses/bus", {
        jwt: g.user.jwt,
        id: window.location.hash.split("#")[1],
      })
        .then(function (r) {
          g.loading = false;
          g.bus = r.response;
        })
        .catch(function (e) {
          console.log(e);
          alert("حدث خطأ..");
          g.loading = false;
        });
    }
  },
  methods: {
    checkPer(e) {
      return checkPer(e);
    },
    done() {
      var g = this;
      if (!g.bus.group_id) {
        alert("برجاء اختيار خط السير");
        return;
      }
      if (!g.bus.section_id) {
        alert("برجاء اختيار القسم");
        return;
      }
      g.loading = true;
      $.post(api + "/user/busses/add-or-edit", {
        jwt: g.user.jwt,
        bus: g.bus,
        edit: g.edit,
      })
        .then(function (r) {
          g.loading = false;
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "تم بنجاح",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch(function (e) {
          console.log(e);
          alert("حدث خطأ..");
          g.loading = false;
        });
    },
  },
};
</script>

<style>
</style>